// General variables
$theme-font-color: #9B9B9B;
$theme-body-font-color: #3D434A;
$theme-body-sub-title-color: $theme-font-color;
$body-color: #f8f8f8;
$main-body-color: #F4F7F9;

// Theme colors variables
$primary-color: #ee1b24;
$light-primary-color: #ffe4e4;
$secondary-color: #48A3D7;
$tertiary-color : #C95E9E;
$success-color: #0DA759;
$info-color : #16C7F9;
$warning-color: #D77748;
$danger-color: #E44141;
$light-color : #f4f4f4;
$badge-light-color: #52526c;
$light-gray : rgba(#6A7185, 30%);
$light-widget-border: #EFEEFF;
$light-semi-gray :#efefef;
$font-gray-color: #6C757D;
$dark-light :$theme-font-color;
$dark-gray: #898989;
$dark-color : #2c323f;
$theme-top-bar-color: #000000;
$dark-color-light: #2c323fb8;
$gray-60 : rgba(#6A7185, 0.30);
$card-body-color : #f9f9fa;
$transparent-color: transparent;
$auth-bg-color: #fafafa;
$light : #fdfeff;
$semi-dark :#59667a;
$light-text: #999999;
$light-paragraph: #8F8F8F;
$light-background: #d7e2e9;
$light-font: rgba(27, 49, 85, 0.4);
$light-semi-font: $semi-dark;
$light-chartist-grid-color: #f6f9fd;
$pitch-color: #fe8a7d;
$color-scarpa: #4c5667;
$color-fiord: #455a64;
$black : #000;
$white: #fff;
$off-white: #F7F6FF;
$horizontal-bar-color: #000000;
$theme-medium-color: #eae8ff;
$dropdown-border-color: #918E99;
$light-primary: #E0DDFF;
$light-secondary: #c7eaff;
$light-tertiary: #fbd8ed;
$light-success: #c8f9e1;
$light-info: #d0f3fd;
$light-warning: #fbdac9;
$light-danger: #FEDDDB;
$light-white: #F9F9FB;
$light-dark: #EAEBF2;
$sidebar-color: #ee1b24;
$sidebar-font: #CBCCCE;
$light-sidebar-bg: #374462;
$header-light-bg: #F4F7F9;
$card-header-color: #6A7185;
$light-text-color: #8D8D8D;
$loader-bg-color: #ffffff80;
$generic-module-color: #f4f6fb;
$misty-rose: #FFEDEE;
$assessment-timer-color: #fd7e14;
$soft-pink: #F7F4F4;
$warm-yellow : #FFD028;
$dark-yellow : #FAE7A3;
$gray-50: #F9FAFB;
$forest-green: #19923B;
$medium-gray: #7F7F7F;
$pale-yellow: #FFE8A9;
$semi-transparent-red : rgba(255, 0, 0, 0.2);
$semi-transparent-red-light :  rgba(255, 0, 0, 0.1);
$golden-yellow:#FFDA4D;
$pale-gray:#E4E4E4;
$pale-red:#f5b4b4;

// gradient colors
$gradient-primary: linear-gradient(26deg, var(--theme-deafult) 0%, #a26cf8 100%);
$gradient-bg-primary: linear-gradient(103.75deg, #33B1EE -13.9%, var(--theme-deafult) 79.68%);

//social colors
$fb: #50598e;
$twitter: #6fa2d8;
$google-plus: #c64e40;
$linkedin: #0077B5;

// soical table 
$fb-color: #2F84F4;
$insta-color: #FC971E;
$pintrest-color: $secondary-color;
$twitter-color: #57B9F6;
$youtube-color: #F42F2F;

//error input color start
$light-body-bg-color: #fafdff;

//multi progress bar colors
$pb-success : #4BC685;
$pb-warning: #FF7F2D;
$pb-error: #FF2D2D;
$pb-info: #16C7F9;
$pb-assigned: #CBB910;

//badge colors
$badge-approved : #BEFF97;

//available status colors
$bg-available: #d3fed6;
$bg-not-available: #e3e3e3;

//fonts
$font-outfit: 'Outfit', sans-serif;
$font-serif: sans-serif;
$landing-cursive: 'Caveat', cursive;
$font-merriweather: 'Merriweather Sans', sans-serif;
$font-themify: themify;
$font-awesome: FontAwesome;
$font-ICO: IcoFont;
$font-lato: 'Lato';

// dark layout variables
$dark-body-background: $light-sidebar-bg;
$dark-card-background: $sidebar-color;
$dark-card-inbox: $light-sidebar-bg;
$dark-small-font-color: #98a6ad;
$dark-all-font-color: rgba(255, 255, 255, 0.7);
$light-all-font-color: rgba(255, 255, 255, 0.2);
$dark-border-color: $dark-body-background;
$dark-card-border: rgba(255, 255, 255, 0.30);
$dark-editor-document: #2b2b2b;
$bg-dark-color: #1f2533;
$dark-datatable-odd: $sidebar-color;
$dark-datatable-sorting: $sidebar-color;
$dark-datatable-sorting-even: $light-sidebar-bg;
$dark-even-hover-sorting: rgba(255, 255, 255, 0.2);

//General tag settings
$body-font-size: 14px;
$body-font-color: $theme-body-font-color;
$ul-padding-left: 0px;
$ancher-color: var(--theme-deafult);
$btn-focus-box-shadow: none;
$all-focus-outline-color: var(--theme-deafult);

$paragraph-font-size : 14px;
$paragraph-line-height : 1.4;
$paragraph-letter-spacing : 0.7px;

$code-tag-color: var(--theme-deafult);
$code-tag-bg-color: rgba($primary-color, 0.03);
$code-tag-padding: 3px;
$code-tag-margin: 0 3px;
$code-tag-border-radious: 2px;

$blockquote-padding : 8px;
$blockquote-border : 4px solid $primary-color;

$small-tag-padding: 10px;
$small-tag-color: $dark-color;

$pre-tag-bg-color: rgba($primary-color, 0.03);
$pre-tag-padding: 20px 0;

$list-group-active-bg-color: var(--theme-deafult);
$list-group-active-border-color: var(--theme-deafult);
$list-group-img-size: 40px;
$list-group-margin: 10px;


// Tables settings
$table-b-margin: 0px;
$table-heading-color: $theme-body-font-color;
$table-row-color: $theme-body-font-color;
$table-footer-font-weight: bold;
$table-inverse-color: $white;
$table-hover-color: $light;
$table-padding: 0.75rem;
$table-xl-padding: 1.25rem 2rem;
$table-xl-font: 120%;
$table-lg-padding: .9rem 2rem;
$table-lg-font: 110%;
$table-de-padding: .75rem 2rem;
$table-de-font: 100%;
$table-sm-padding: .5rem 2rem;
$table-sm-font: 90%;
$table-xs-padding: 0.2rem 2rem;
$table-xs-font: 80%;
$horizontal-border-color: #f2f4ff;
$horizontal-padding: .75rem 2rem;
$table-inverse-bg-color: #292b2c;
$bright_green : #2FC816;
$vivid_blue : #4541FF;
$charcoal_gray :#3E3D46;
$vivid_green : #0BA62D;
$magenta_pink : #F72E9B;
$danger_red : #dc3545;
$lime-green : #37D31E;


//According setting
$according-card-top-margin : 10px;
$according-btn-weight : 500;
$according-btn-color : $theme-body-font-color;
$according-card-header-padding : 0.75rem 1.25rem;
$according-card-header-icon : $font-ICO;
$according-open-icon : "\eb73";
$according-close-icon : "\eb7a";

//Alert settings
$alert-hover-color :$dark-color;
$alert-border-radious :0.15rem;
$alert-padding :15px;
$alert-msg-icon-size :16px;

//badge settings
$badge-padding :0.44em 0.7em;
$badge-svg-size :18px;

//form input settings
$form-group-margin-bottom : 1.25em;
$col-form-label-font-size : 14px;
$form-control-font-size : 14px;
$form-control-border-radious : 2px;
$form-control-border-color : $light-gray;

//breadcrumb setting
$breadcrumb-ancher-color : $white;
$breadcrumb-ancher-dark-color : $black;

//buttons setting
$btn-font-size : 14px;
$btn-padding : 0.375rem 1.75rem;
$btn-lg-font-size: 18px;
$btn-sm-font-size: 12px;
$btn-xs-font-size: 11px;
$btn-xs-padding: 0.05rem 0.4rem;

//Card settings
$card-padding :10px 15px;
$card-margin-bottom :30px;
$card-border-color :1px solid $light-gray;
$card-border-radious :17px;
$common-shadow-color : rgba($info-color, 0.08);
$card-box-shadow : 0px 9px 20px rgba(46, 35, 94, 0.07);
$card-header-bg-color : $white;
$card-header-font-transform : capitalize;
$card-header-span-size : 14px;
$card-header-span-color : $theme-body-sub-title-color;
$card-body-bg-color : $transparent-color;
$card-footer-bg-color : $white;
$card-hover-box-shadow : 0 0 40px rgba(8, 21, 66, .05);



// According settings
$card-border-width :0px;
$card-header-font-weight : 600;
$card-header-font-size : 18px;
$card-header-font-color : $theme-body-font-color;

//footer settings
$footer_bg_color : $white;
$footer_box_shadow : 0 0 20px rgba($semi-dark, 0.1);
$footer_dark_color : $theme-font-color;
$footer_dark__txt_color : $white;

//form settings
$form-group-margin-bottom :20px;
$sm-form-margin-bottom : 14px;
$form-placeholder-color : $dark-gray;
$form-placeholder-font-size : 14px;
$form-placeholder-letter-spacing : 1px;
$form-placeholder-font-weight : 100;
$form-input-border-color : $light-semi-gray;
$form-input-bg-color : $white;

//Tour settings
$tour-color: var(--theme-deafult);

//sidabr main settings
$sidebar-width: 255px;
$sidebar-position: fixed;
$sidebar-background-color: $white;
$sidebar-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
$sidebar-overflow: auto;
$sidebar-z-index: 9;
$sidebar-transition: 0.5s;
$sidebar-fill-icon-color: #757589;

//sidebar profile settings
$sidebar-profile-edit-icon-size: 14px;
$sidebar-profile-name-txt-color : var(--theme-deafult);
$sidebar-profile-name-txt-transfer : uppercase;
$sidebar-profile-name-txt-weight : 600;
$sidebar-profile-name-letter-specing : 1.5px;
$sidebar-profile-name-marging : 3px;
$sidebar-profile-sub-title-font-size: 10px;
$sidebar-profile-sub-title-margin: 0px;
$sidebar-profile-img-shadow: 0 0 15px rgba(68, 102, 242, 0.3);

//Sidebar main menu setting
$sidebar-menu-padding: 15px;
$sidebar-menu-list-style: none;
$sidebar-menu-list-margin: 0;
$sidebar-icon-size: 14px;
$sidebar-icon-margin: 14px;
$sidebar-icon-stroke-width: 3px;
$sidebar-font-size: 14px;
$sidebar-letter-specing: 0.5px;
$sidebar-txt-transform: capitalize;
$sidebar-font-weight: 600;
$sidebar-font-color: $theme-body-font-color;
$sidebar-padding-top: 12px;
$sidebar-padding-bottom: 12px;
$sidebar-sub-header-padding: 15px;
$sidebar-sub-header-margin: 0;
$sidebar-heading-hover-padding: 5px;
$sidebar-hover-txt-color: var(--theme-deafult);
$sidebar-arrow-margin-top: 2px;
$sidebar-arrow-size: 15px;
$sidebar-arrow-color: $theme-body-font-color;
$sidebar-open-icon: "\f107";
$sidebar-close-icon: "\f105";
$sidebar-icon-font-family: $font-awesome;
$sidebar-height: calc(100vh - 105px);
$sidebar-compact-width: 260px;

//Header settings
$main-header-position: fixed;
$main-header-top: 0;
$main-header-shadow: 0 0 20px rgba($semi-dark, 0.1);
$main-header-shadow-1: 0px 4px 40px rgba(39, 32, 120, 0.1);

//page settings
$page-body-padding : 0 15px 0 15px;
$page-body-bg-color: $light-color;
$page-body-margin-bottom: 0px;
$page-title-padding : 15px 30px;
$common-box-shadow: 2px 3.464px 8px 0px rgba(22, 145, 248, 0.18);
$page-title-font-size: 24px;
$page-title-margin-bottom: 0;
$page-title-text-tranform: capitalize;
$page-small-title-display: block;
$page-small-title-font-size: 12px;
$page-small-title-margin-bottom: 5px;
$page-small-title-text-transform: capitalize;
$page-small-title-text-color: $theme-body-sub-title-color;
$breadcrumb-size: 16px;
$breadcrumb-content: "/";


//main header left settings
$header-left-bg-color : $white;
$main-header-padding : 27px 22px;
$main-header-z-index : 8;
$header-wrapper-padding: 15px 30px;
$header-wrapper-nav-right : 0px;
$header-wrapper-nav-icon-size : 20px;
$header-wrapper-nav-icon-color : $theme-font-color;
$header-wrapper-nav-icon-align : middle;
$header-size: 136px;
$box-layout-space : 40px;

// typography variables
$heading_1: 27px;
$heading_2: 23px;
$heading_3: 19px;
$heading_4: 17px;
$heading_5: 13px;
$heading_6: 11px;
$common_letter_spacing: 0.3px;

// widgets variables
$widget-shadow: 1px 2px 21px -2px rgba(214, 214, 227, 0.83);
$light-shade-gray : #fafafa54;
$white-smoke:#F5F7F9;
$pale-gray-shade: #D4FFD5;
$pink-shade: #FFD2D2;
$thin-light-gray:#f0f0f0;
$soft-gray:#f2f2f2;
$ash-gray: #E7E7E7;
$red-rust : #d2411a
