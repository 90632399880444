@import "../../../public/assets/scss/utils/variables";

.download-certificate {
  &__container {
    padding: 1.2rem;
    border: 1px solid $light-gray;
    margin-bottom: 1rem;
    border-radius: 1rem;
  }

  &__name {
    flex: 1;
    word-break: break-word;
  }
}
