.schedule-details {
  &__resources {
    list-style-type: unset;
    padding-left: 1rem;
  }

  &__course-data {
    &__item {
      margin-bottom: 0.8rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__course-includes {
    &__item {
      margin-bottom: 0.8rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
