@import "../../../public/assets/scss/utils/variables";

.custom-skill-table {
  border: none !important;
}

.custom-skill-table-thead {
  border-style: hidden;
}

.lo-cell-skill-update {
  width: 40%;
}

.custom-skill-table-tbody tr td {
  &:last-child {
    border-style: hidden;
  }
}

.badge-approved-bg {
  background-color: $badge-approved !important;
}

.notification-wrapper {
  position: relative;
  display: inline-block;
}

.doc-notification-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red !important;
  color: white;
  border-radius: 50%;
  padding: 3px 7px;
  font-size: 0.75rem;
}
