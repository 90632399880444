.lo-cell {
  width: 40%;
}

.assessment-duration-cell {
  width: 21%;
}

.modules-cell {
  width: 10%;
}

.assessment-list-table-style {
  &__table {
    .rdt_TableCol:last-child,
    .rdt_TableCell:last-child {
      position: sticky;
      right: 0;
      z-index: 1;
      background-color: inherit;
    }

    .rdt_TableCol:nth-last-child(2),
    .rdt_TableCell:nth-last-child(2) {
      position: sticky;
      right: 2rem;
      z-index: 1;
      background-color: inherit;
    }
  }
}
