.sga-list-table {
  .rdt_TableHead {
    z-index: 2 !important;
  }

  .rdt_TableCol:first-child,
  .rdt_TableCell:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: inherit;
  }

  .rdt_TableCol:last-child,
  .rdt_TableCell:last-child {
    position: sticky;
    right: 0;
    z-index: 1;
    background-color: inherit;
  }
}
