@import "../../../../../public/assets/scss/utils/variables";

.asset-doc-notification-badge {
  position: absolute;
  top: 0px;
  background-color: red !important;
  color: white;
  border-radius: 50%;
  padding: 3px 7px;
  font-size: 0.75rem;
}

.asset-list-table {
  .rdt_TableCol:last-child,
  .rdt_TableCell:last-child {
    position: sticky;
    right: 0;
    z-index: 2;
    background-color: inherit;
  }

  .rdt_TableCol:first-child,
  .rdt_TableCell:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: inherit;
  }

  .rdt_TableCol:nth-last-child(2),
  .rdt_TableCell:nth-last-child(2) {
    position: sticky;
    right: 6.5rem;
    z-index: 1;
    background-color: inherit;
  }

  .retired {
    color: $magenta_pink;
  }
  .need_attention,
  .replaced .need_repair {
    color: $youtube-color;
  }
  .active {
    color: $lime-green;
  }
}
