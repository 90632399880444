@import "../../../public/assets/scss/utils/variables";

#multiple-datasets {
  position: relative;
}

.rbt-input-multi.focus {
  border-color: $primary-color;
  box-shadow: none !important;
}

.rbt-token {
  color: $primary-color;
  background-color: $light-primary-color;
}

.rbt-menu {
  max-width: 100%;
  .dropdown-item {
    color: black;
    border-bottom: none;
    &:hover {
      color: white;
      background-color: $primary-color;
    }
  }
}

.multiple-datasets {
  &__down-arrow {
    position: absolute;
    right: 10px;
    top: 54%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    background-color: white;
  }
}

.rbt-input-multi .rbt-input-main {
  padding-right: 1.1rem !important;
}
