@import "../../../public/assets/scss/utils/variables";

.custom-table-cell {
  border: 0.1rem solid $black;
  padding: 0.5rem;
  text-align: center;
  min-width: 9rem;
}

.rounded-card {
  margin: 0.2rem;
  border-radius: 0.9rem;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  color: $white;
  padding: 0.75rem 1rem;
  box-shadow: 0rem 0.125rem 0.3125rem $black;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &.small {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
  }

  &.medium {
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
  }

  &.large {
    padding: 1rem 1.25rem;
    font-size: 1rem;
  }
}
